/**
 * Sanitizes the FTP path by removing invalid characters and ensuring the path starts with a slash (/).
 *
 * @param {string} path - The FTP path to sanitize.
 * @returns {string} - The sanitized FTP path.
 */
export function sanitizeFtpPath(path: string): string {
  let sanitizedPath = path.replace(/[^a-zA-Z0-9-._~/+,:;='()[\]{} ]/g, ""); // Remove invalid characters
  if (!sanitizedPath.startsWith("/")) {
    sanitizedPath = "/" + sanitizedPath; // Ensure path starts with a slash
  }
  return sanitizedPath;
}

/**
 * Handles the back navigation in the FTP history stack.
 *
 * @param {string[]} historyStack - The stack containing the previous FTP paths.
 * @param {string[]} forwardStack - The stack for forward navigation.
 * @param {string} currentPath - The current FTP path.
 * @returns {string} - The previous path to navigate to.
 */
export function ftpBack(historyStack: string[], forwardStack: string[], currentPath: string): string {
  if (historyStack.length > 0) {
    const previousPath = historyStack.pop() as string;
    forwardStack.push(currentPath); // Push the current path to forward stack
    return previousPath;
  }
  return currentPath;
}

/**
 * Handles the forward navigation in the FTP forward stack.
 *
 * @param {string[]} historyStack - The stack containing the previous FTP paths.
 * @param {string[]} forwardStack - The stack for forward navigation.
 * @param {string} currentPath - The current FTP path.
 * @returns {string} - The next path to navigate to.
 */
export function ftpForward(historyStack: string[], forwardStack: string[], currentPath: string): string {
  if (forwardStack.length > 0) {
    const nextPath = forwardStack.pop() as string;
    historyStack.push(currentPath); // Push the current path to history stack
    return nextPath;
  }
  return currentPath;
}

/**
 * Handles navigating up to the parent directory in the FTP path.
 *
 * @param {string} currentPath - The current FTP path.
 * @returns {string} - The parent directory path.
 */
export function ftpUp(currentPath: string): string {
  const trimmedPath = currentPath.trim();
  if (trimmedPath !== "/") {
    return trimmedPath.split("/").slice(0, -1).join("/") || "/";
  }
  return currentPath;
}

/**
 * Appends a subpath to the current FTP path.
 *
 * @param {string} currentPath - The current FTP path.
 * @param {string} subPath - The subpath to append.
 * @returns {string} - The new combined path.
 */
export function appendToFtpPath(currentPath: string, subPath: string): string {
  const sanitizedSubPath = subPath.startsWith("/") ? subPath.slice(1) : subPath;
  return `${currentPath.replace(/\/$/, "")}/${sanitizedSubPath}`;
}

/**
 * Updates a specific CSV header value and clears any existing error for that header.
 *
 * @param {{ value: string; originalValue: string; error: boolean }[]} headers - The list of CSV headers.
 * @param {string} newHeaderValue - The new header value to update.
 * @param {number} index - The index of the header to update.
 * @param {boolean} error - The new error value to update
 */
export function updateCsvHeaderValue(
  headers: { value: string; originalValue: string; error: boolean }[],
  newHeaderValue: string,
  index: number,
  error = false,
): void {
  headers[index].value = newHeaderValue; // Update header value
  headers[index].error = error;
}

/**
 * Updates the rows in a CSV to reflect changes in a column header.
 * Moves the content from the current header to the new header and applies the correct error state.
 *
 * @param {Array<Record<string, { value: string; error: boolean }>>} rows - The first ten rows of the CSV file, where each row is an object representing column headers with values and error states.
 * @param {string} newHeaderValue - The new header value that will replace the current header.
 * @param {string} currentHeader - The current header to be replaced.
 * @param {boolean} isError - Indicates whether the new header should be marked as an error.
 * @returns {Array<Record<string, { value: string; error: boolean }>>} - The updated rows with the new header and corresponding error state.
 *
 * The function does the following:
 * 1. Maps through the rows, replacing the current header with the new header value.
 * 2. Retains the cell content from the current header but assigns it to the new header.
 * 3. Deletes the old header from the row.
 * 4. Sets the correct error state for the new header based on the `isError` parameter.
 */
export function updateRowsForNewHeader(
  rows: Array<Record<string, { value: string; error: boolean }>>,
  newHeaderValue: string,
  currentHeader: string,
  isError = false,
): Array<Record<string, { value: string; error: boolean }>> {
  return rows.map((row) => {
    const updatedRow = { ...row };
    // Set the new header value while retaining the old value and the correct error state
    updatedRow[newHeaderValue] = {
      value: updatedRow[currentHeader]?.value,
      error: isError, // Apply the error state based on the function parameter
    };
    // Remove the old header
    delete updatedRow[currentHeader];
    return updatedRow;
  });
}

/**
 * Calculates the Damerau-Levenshtein distance between two strings.
 * This distance is a string metric that measures the minimum number of operations
 * (insertions, deletions, substitutions, or transpositions) required to transform one string into another.
 *
 * @param {string} a - The first string to compare.
 * @param {string} b - The second string to compare.
 * @returns {number} - The Damerau-Levenshtein distance between the two strings.
 */
export function damerauLevenshtein(a: string, b: string): number {
  const m = a.length;
  const n = b.length;
  const dp = Array.from(Array(m + 1), () => Array(n + 1).fill(0));
  for (let i = 0; i <= m; i++) dp[i][0] = i;
  for (let j = 0; j <= n; j++) dp[0][j] = j;
  for (let i = 1; i <= m; i++) {
    for (let j = 1; j <= n; j++) {
      const cost = a[i - 1] === b[j - 1] ? 0 : 1;
      dp[i][j] = Math.min(dp[i - 1][j] + 1, dp[i][j - 1] + 1, dp[i - 1][j - 1] + cost);

      // Handle transposition
      if (i > 1 && j > 1 && a[i - 1] === b[j - 2] && a[i - 2] === b[j - 1]) {
        dp[i][j] = Math.min(dp[i][j], dp[i - 2][j - 2] + 1);
      }
    }
  }
  return dp[m][n];
}

/**
 * Checks if two strings match based on several criteria: substring containment,
 * length proximity, and the Damerau-Levenshtein distance. This is useful for flexible matching.
 *
 * @param {string} a - The first string to compare.
 * @param {string} b - The second string to compare.
 * @returns {boolean} - Returns true if the strings match based on the defined criteria.
 */
export function tokenizedMatchWithSubstring(a: string, b: string): boolean {
  const lowerA = a.toLowerCase();
  const lowerB = b.toLowerCase();
  // First check for substring containment
  if (lowerA.includes(lowerB) || lowerB.includes(lowerA)) {
    return true;
  }
  // Second check: ensure length proximity (length difference should not be too large)
  const lengthDifference = Math.abs(a.length - b.length);
  if (lengthDifference > 5) {
    // Set a reasonable threshold for length differences
    return false; // Reject if lengths are too far apart
  }
  // Third check: use Damerau-Levenshtein for further flexible matching
  const distance = damerauLevenshtein(a, b);
  return distance <= 3; // Allow a flexible distance threshold (e.g., 3)
}

/**
 * Maps the provided CSV headers to the template headers and identifies any errors.
 * If no template header matches a CSV header, it is marked as an error.
 *
 * @param {Array<{originalValue: string, value: string, error: boolean}>} csvHeaders - The headers from the uploaded CSV file.
 * @param {string[]} templateHeaders - The expected headers from the template.
 * @param {number} threshold - The maximum allowable distance for a header match using Damerau-Levenshtein distance.
 * @returns {{updatedCsvHeaders: {originalValue: string, value: string, error: boolean}[]}} - The updated CSV headers with matched template headers and error flags.
 */
export function mapCsvHeadersToTemplate(
  csvHeaders: { originalValue: string; value: string; error: boolean }[],
  templateHeaders: string[] | undefined,
  threshold = 3, // Default threshold for Damerau-Levenshtein distance
): {
  updatedCsvHeaders: { originalValue: string; value: string; error: boolean }[];
} {
  const matchedTemplateHeaders = new Set<string>(); // Track already matched template headers
  const updatedCsvHeaders = csvHeaders.map((header) => {
    if (!templateHeaders || templateHeaders.length === 0) {
      return { ...header, error: true };
    }
    // Try to find a match using tokenized match with substring
    let closestMatch = templateHeaders.find(
      (templateHeader) =>
        !matchedTemplateHeaders.has(templateHeader) && tokenizedMatchWithSubstring(header.value, templateHeader),
    );
    // If a match is found, check if it has already been assigned
    if (closestMatch) {
      matchedTemplateHeaders.add(closestMatch); // Mark the template header as used
      return { originalValue: header.originalValue, value: closestMatch, error: false };
    }
    // Fallback: use Damerau-Levenshtein distance if no substring match
    closestMatch = templateHeaders[0];
    let minDistance = damerauLevenshtein(header.value, closestMatch);
    for (const templateHeader of templateHeaders) {
      if (!matchedTemplateHeaders.has(templateHeader)) {
        const distance = damerauLevenshtein(header.value, templateHeader);
        if (distance < minDistance) {
          minDistance = distance;
          closestMatch = templateHeader;
        }
      }
    }
    // Check if the minimum distance is within the threshold
    if (minDistance <= threshold) {
      matchedTemplateHeaders.add(closestMatch); // Mark the template header as used
      return { originalValue: header.originalValue, value: closestMatch, error: false };
    } else {
      // If not within the threshold, return the original CSV header with an error
      return { ...header, error: true };
    }
  });
  return { updatedCsvHeaders };
}
