import { defineStore } from "pinia";
import { parse, unparse } from "papaparse";
import type { UseFetchOptions } from "@vueuse/core";
import lodash from "lodash";
import { useCookie, useFetch, useRuntimeConfig } from "#app";
import {
  sanitizeInput,
  sanitizeFtpPath,
  ftpBack,
  ftpForward,
  ftpUp,
  appendToFtpPath,
  updateCsvHeaderValue,
  updateRowsForNewHeader,
  handleReadFirstChunk,
  mapCsvHeadersToTemplate,
  mapAndSortDropdownValues,
  mapCsvHeaders,
  mapFirstTenRows,
} from "~/utils";
import { CAMP_DELIMITER_ID, CAMP_TEMPLATE_ID, DESCRIPTION_LIMIT, NAME_LIMIT } from "~/data";
import useRefreshToken from "~/composables/useRefreshToken";
import { getSegmentData, getRegions } from "~/composables/useFetchData";

const { BASE_URL } = useRuntimeConfig().public;
const auth = useCookie<AuthResponse>("auth");
const { refreshToken } = useRefreshToken();

export const ftpSettingsInitialState = {
  showFiles: false,
  connectionId: null,
  path: "/",
  debouncedPath: "/",
  ftpFile: null,
  files: [],
  errorMessage: "",
  historyStack: [],
  forwardStack: [],
  fileSelected: false,
};
export const segmentCreationInitialState = {
  guid: null,
  name: "",
  description: "",
  selectedRegionId: null,
  selectedFileTypeId: null,
  selectedDelimiterId: null,
  selectedFile: null,
  selectedFileName: null,
  csvHeaders: [],
  firstTenRows: [],
  ftp: lodash.cloneDeep(ftpSettingsInitialState),
  errors: {
    show: false,
    doNotUpload: false,
    moreTemplateHeadersThanCsvHeaders: false,
    moreCsvHeadersThanTemplateHeaders: false,
  },
};
export const onBoarderStoreInitialState = {
  step: 0,
  loading: false,
  regions: [],
  delimiters: [],
  templates: [],
  segmentCreation: lodash.cloneDeep(segmentCreationInitialState),
};

export const useOnBoarderStore = defineStore("onBoarderStore", {
  state: (): OnBoarderStateType => onBoarderStoreInitialState,
  getters: {
    bodyRequest(state) {
      return {
        name: state.segmentCreation.name,
        description: state.segmentCreation.description,
        region: state.segmentCreation.selectedRegionId,
        file_name: state.segmentCreation.ftp.showFiles
          ? state.segmentCreation.ftp.ftpFile
          : state.segmentCreation.selectedFile?.name,
        col_mapping: state.segmentCreation.csvHeaders.map((header) => ({
          col_name: header.originalValue,
          given_col_name: header.value,
          upload: header.value.includes("Do_not_upload"),
        })),
        preview: [
          state.segmentCreation.csvHeaders.map((header) => header.value),
          ...state.segmentCreation.firstTenRows.map((row) => Object.values(row).map((cell) => cell.value)),
        ],
        file_type: CAMP_TEMPLATE_ID,
        delimiter: CAMP_DELIMITER_ID,
      };
    },
    validStepOne(state) {
      return (
        state.segmentCreation.name !== "" &&
        state.segmentCreation.description !== "" &&
        state.segmentCreation.selectedRegionId !== null
      );
    },
    validStepTwo(state) {
      return (
        state.segmentCreation.selectedFile !== null ||
        (state.segmentCreation.ftp.showFiles && state.segmentCreation.ftp.ftpFile !== null) ||
        state.segmentCreation.selectedFileName !== null
      );
    },
    validStepThree(state) {
      return (
        !state.segmentCreation.csvHeaders.some((header) => header.error) &&
        !state.segmentCreation.csvHeaders.every((header) => header.value.includes("Do_not_upload"))
      );
    },
    disableNextButton() {
      if (this.step === 1) {
        return !this.validStepOne;
      } else if (this.step === 2) {
        return !this.validStepTwo;
      } else if (this.step === 3) {
        return !this.validStepThree;
      } else {
        return true;
      }
    },
  },
  actions: {
    setStep(value: number) {
      this.step = value;
    },
    setStepBack() {
      this.step--;
    },
    setStepNext() {
      this.step++;
      const currentStep = this.step;
      if (currentStep === 4) {
        this.resetSegmentCreation();
      }
    },
    setLoading(value: boolean) {
      this.loading = value;
    },
    handleNameInput(event: Event) {
      const target = event.target as HTMLInputElement;
      const value = sanitizeInput(target.value, NAME_LIMIT);
      target.value = value;
      this.segmentCreation.name = value;
    },
    handleDescriptionInput(event: Event) {
      const target = event.target as HTMLTextAreaElement;
      let value = target.value;
      if (value.length > DESCRIPTION_LIMIT) {
        value = value.substring(0, DESCRIPTION_LIMIT);
      }
      target.value = value;
      this.segmentCreation.description = value;
    },
    setSelectedRegionId(id: number) {
      this.segmentCreation.selectedRegionId = id;
    },
    handleFtpPathInput(event: Event) {
      const target = event.target as HTMLInputElement;
      const value = sanitizeFtpPath(target.value);
      target.value = value;

      if (value !== this.segmentCreation.ftp.path) {
        this.segmentCreation.ftp.historyStack.push(this.segmentCreation.ftp.path);
        this.segmentCreation.ftp.forwardStack = [];
      }

      this.segmentCreation.ftp.path = value;
      this.handleDebouncedFtpPathInput();
    },
    handleDebouncedFtpPathInput() {
      lodash.debounce(() => {
        this.segmentCreation.ftp.debouncedPath = this.segmentCreation.ftp.path;
      }, 1000)();
    },
    appendToFtpPath(subPath: string) {
      const newPath = appendToFtpPath(this.segmentCreation.ftp.path, subPath);
      this.segmentCreation.ftp.historyStack.push(this.segmentCreation.ftp.path); // Save current path to history
      this.segmentCreation.ftp.path = newPath;
      this.segmentCreation.ftp.debouncedPath = newPath;
      this.segmentCreation.ftp.forwardStack = []; // Clear forward stack after appending a new path
    },
    navigateToFtpPath(path: string) {
      if (this.segmentCreation.ftp.path !== path) {
        this.segmentCreation.ftp.historyStack.push(this.segmentCreation.ftp.path);
        this.segmentCreation.ftp.path = path;
        this.segmentCreation.ftp.debouncedPath = path;
        this.segmentCreation.ftp.forwardStack = [];
      }
    },
    handleFtpBackClick() {
      const previousPath = ftpBack(
        this.segmentCreation.ftp.historyStack,
        this.segmentCreation.ftp.forwardStack,
        this.segmentCreation.ftp.path,
      );
      this.segmentCreation.ftp.path = previousPath;
      this.segmentCreation.ftp.debouncedPath = previousPath;
    },
    handleFtpForwardClick() {
      const nextPath = ftpForward(
        this.segmentCreation.ftp.historyStack,
        this.segmentCreation.ftp.forwardStack,
        this.segmentCreation.ftp.path,
      );
      this.segmentCreation.ftp.path = nextPath;
      this.segmentCreation.ftp.debouncedPath = nextPath;
    },
    handleFtpUpClick() {
      const parentPath = ftpUp(this.segmentCreation.ftp.path);
      this.segmentCreation.ftp.path = parentPath;
      this.segmentCreation.ftp.debouncedPath = parentPath;
    },
    setSelectedFtpFile(value: string) {
      this.segmentCreation.ftp.ftpFile = value;
      if (this.segmentCreation.selectedFileName !== null) {
        this.segmentCreation.selectedFileName = null;
      }
      if (this.segmentCreation.selectedFile !== null) {
        this.segmentCreation.selectedFile = null;
      }
    },
    resetSegmentCreation() {
      this.segmentCreation = lodash.cloneDeep(segmentCreationInitialState);
    },
    updateColumnValues(newHeaderValue: string, currentHeader: string, index: number, error = false) {
      // Update the header value and reset the error flag
      updateCsvHeaderValue(this.segmentCreation.csvHeaders, newHeaderValue, index, error);
      // Update the first ten rows with the new header while retaining the content
      this.segmentCreation.firstTenRows = updateRowsForNewHeader(
        this.segmentCreation.firstTenRows,
        newHeaderValue,
        currentHeader,
        error,
      );
    },
    handleSelectHeader(header: string, index: number) {
      const currentHeader = this.segmentCreation.csvHeaders[index].value;
      // If the selected header is the same as the current one, do nothing
      if (header === currentHeader) {
        return;
      }
      if (header === "Do not upload") {
        // Set the doNotUpload flag to true
        this.segmentCreation.errors.doNotUpload = true;
        // Find the count of existing "Do_not_upload" headers
        const uploadCount = this.segmentCreation.csvHeaders.filter((col) =>
          col.value.startsWith("Do_not_upload"),
        ).length;
        // Set the new header as "Do_not_upload_N"
        const newHeaderValue = `Do_not_upload_${uploadCount + 1}`;
        // Update the current header with the new value
        this.updateColumnValues(newHeaderValue, currentHeader, index);
      } else if (header === "Others") {
        // Find the count of existing "Others" headers
        const othersCount = this.segmentCreation.csvHeaders.filter((col) => col.value.startsWith("Others")).length;
        // Set the new header as "Others_N"
        const newHeaderValue = `Others_${othersCount + 1}`;
        // Update the current header with the new value
        this.updateColumnValues(newHeaderValue, currentHeader, index);
      } else {
        // Handle regular headers
        const existingIndex = this.segmentCreation.csvHeaders.findIndex(
          (col, idx) => col.value === header && idx !== index,
        );
        // Update with new header or set error if a duplicate is found
        this.updateColumnValues(
          header,
          existingIndex !== -1 ? `Column_${existingIndex + 1}` : currentHeader,
          index,
          existingIndex !== -1,
        );
      }
      // Set the errors.show flag if any header has an error
      this.segmentCreation.errors.show = this.segmentCreation.csvHeaders.some((header) => header.error);
    },
    setFtpFileDisplay(value: boolean) {
      this.segmentCreation.ftp.showFiles = value;
    },
    resetFtpSettings() {
      this.segmentCreation.ftp = lodash.cloneDeep(ftpSettingsInitialState);
    },
    hideError() {
      this.segmentCreation.errors.show = false;
    },
    hideDoNotUpload() {
      this.segmentCreation.errors.doNotUpload = false;
    },
    checkCsvAgainstTemplate(csvTemplateStr: string) {
      const parsed = parse<Record<string, string>>(csvTemplateStr, { preview: 10, header: true });

      const selectedTemplate = this.templates.find((template) => template.id === CAMP_TEMPLATE_ID);
      const templateHeaders = selectedTemplate?.headers.map((header) => header.header);
      const { updatedCsvHeaders } = mapCsvHeadersToTemplate(
        (parsed.meta.fields ?? []).map((field) => ({ originalValue: field, value: field, error: false })),
        templateHeaders,
      );

      this.segmentCreation.errors.show = updatedCsvHeaders.some((header) => header.error);
      this.segmentCreation.csvHeaders = updatedCsvHeaders;
      this.segmentCreation.firstTenRows = parsed.data.map((row) => {
        const completeRow: Record<string, { value: string; error: boolean }> = {};
        updatedCsvHeaders.forEach((header) => {
          const cellValue = row[header.originalValue];
          completeRow[header.value] = { value: cellValue, error: header.error };
        });
        return completeRow;
      });
    },
    handleFileChange(event: Event) {
      const input = event.target as HTMLInputElement;
      if (input.files && input.files[0]) {
        this.segmentCreation.selectedFile = input.files[0];
        if (this.segmentCreation.selectedFileName !== null) {
          this.segmentCreation.selectedFileName = null;
        }
        if (this.segmentCreation.ftp.ftpFile !== null) {
          this.segmentCreation.ftp.ftpFile = null;
        }
      }
    },
    handleDropFile(event: DragEvent) {
      event.preventDefault();
      if (event.dataTransfer && event.dataTransfer.files.length > 0) {
        this.segmentCreation.selectedFile = event.dataTransfer.files[0];
        if (this.segmentCreation.selectedFileName !== null) {
          this.segmentCreation.selectedFileName = null;
        }
        if (this.segmentCreation.ftp.ftpFile !== null) {
          this.segmentCreation.ftp.ftpFile = null;
        }
      }
    },
    async setFtpConnectionId(id: number) {
      this.segmentCreation.ftp.connectionId = id;
      await this.listFilesAndDirectoriesFtp();
    },
    async getOptions() {
      if (this.regions.length === 0 || this.delimiters.length === 0 || this.templates.length === 0) {
        const requestConfig = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${auth.value.access}`,
          },
        };
        const [regions, delimiters, templates] = await Promise.all([
          await getRegions(),
          useFetch<DelimiterResponseType[]>(`${BASE_URL}/api/v2/delimiter/`, requestConfig as UseFetchOptions),
          useFetch<TemplateType[]>(`${BASE_URL}/api/v2/platforms/templates/`, requestConfig as UseFetchOptions),
        ]);
        if (
          regions.error.value?.statusCode === 401 ||
          delimiters.error.value?.statusCode === 401 ||
          templates.error.value?.statusCode === 401
        ) {
          await refreshToken();
        } else if (regions.data.value && delimiters.data.value && templates.data.value) {
          const additionalHeader: HeaderType[] = [
            {
              type: "string",
              header: "Do not upload",
              is_pii: false,
            },
            {
              type: "string",
              header: "Others",
              is_pii: false,
            },
          ];
          this.regions = mapAndSortDropdownValues(
            regions.data.value as unknown as Record<string, string>[],
            "id",
          ).filter((region) => region.name !== "WW");
          this.delimiters = delimiters.data.value.map((delimiter) => ({
            id: delimiter.id,
            name: `${delimiter.display_name} (${delimiter.symbol})`,
          }));
          this.templates = templates.data.value.map((template) => {
            return {
              ...template,
              headers: [...template.headers, ...additionalHeader],
            };
          });
        }
      }
    },
    async processSelectedFile() {
      const { selectedFile } = this.segmentCreation;
      if (!selectedFile) return;

      const fileContent = await handleReadFirstChunk(selectedFile);
      this.checkCsvAgainstTemplate(fileContent);
    },
    async listFilesAndDirectoriesFtp() {
      this.loading = true;
      this.segmentCreation.ftp.errorMessage = "";
      this.segmentCreation.ftp.ftpFile = null;
      const bodyRequest = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${auth.value.access}`,
        },
        body: {
          connection_id: this.segmentCreation.ftp.connectionId,
          url_directory: this.segmentCreation.ftp.debouncedPath,
        },
      };
      const { data, error } = await useFetch<{ items: DirectoryType[] }, ConnectionErrorResponse>(
        `${BASE_URL}/api/v2/ftp/list_files_and_directories/`,
        bodyRequest as UseFetchOptions,
      );
      if (error.value) {
        this.segmentCreation.ftp.errorMessage = error.value.error as string;
      } else if (data.value) {
        this.segmentCreation.ftp.files = data.value.items;
      }
      this.loading = false;
    },
    async readFileFtp() {
      const { connectionId, ftpFile, path } = this.segmentCreation.ftp;
      const { guid } = this.segmentCreation;
      if (!ftpFile || !connectionId || !guid) return;

      this.loading = true;
      this.segmentCreation.ftp.errorMessage = "";

      const bodyRequest = {
        headers: {
          Authorization: `Bearer ${auth.value.access}`,
        },
        method: "POST",
        body: {
          connection_id: connectionId,
          url_directory: path,
          file_name: `${ftpFile.split(".csv")[0]}.csv`,
          file_size: ftpFile.split(".csv")[1].replace("_", ""),
          guid: this.segmentCreation.guid,
        },
      };
      const { data } = await useFetch<{ preview: string[][] }>(
        `${BASE_URL}/api/v2/ftp/read_and_upload_file/`,
        bodyRequest as UseFetchOptions,
      );
      if (data.value) {
        const csvString = unparse(data.value.preview);
        this.checkCsvAgainstTemplate(csvString);
      }
      this.loading = false;
    },
    async uploadFile() {
      const { guid, selectedFile } = this.segmentCreation;
      if (!guid || !selectedFile) return;
      this.loading = true;

      const preSignedUrlRequestConfig = {
        headers: {
          Authorization: `Bearer ${auth.value.access}`,
        },
        method: "GET",
      };
      const { data: preSignedUrlData } = await useFetch<GetPreSignedURLResponse>(
        `${BASE_URL}/api/v2/segment/${guid}/generate-presigned-url?file_name=${selectedFile.name}&file_size=${selectedFile.size}`,
        preSignedUrlRequestConfig as UseFetchOptions,
      );

      const uploadFileRequestConfig = {
        headers: {
          "Content-Type": "application/octet-stream",
          "x-amz-meta-segment_guid": this.segmentCreation.guid,
        },
        method: "PUT",
        body: selectedFile,
      };
      await useFetch(preSignedUrlData?.value?.url ?? "", uploadFileRequestConfig as UseFetchOptions);

      this.loading = false;
    },
    async createSegment() {
      const { guid } = this.segmentCreation;
      if (guid) return;
      const requestConfig = {
        headers: {
          Authorization: `Bearer ${auth.value.access}`,
        },
        method: "POST",
        body: {
          name: this.segmentCreation.name,
          description: this.segmentCreation.description,
          region: this.segmentCreation.selectedRegionId,
          file_type: CAMP_TEMPLATE_ID,
          delimiter: CAMP_DELIMITER_ID,
        },
      };
      const { data: segmentData } = await useFetch<SegmentType>(
        `${BASE_URL}/api/v2/segment/`,
        requestConfig as UseFetchOptions,
      );
      if (segmentData.value) {
        this.segmentCreation.guid = segmentData.value?.guid;
      }
    },
    async editSegment() {
      if (!this.segmentCreation.guid) return;
      this.loading = true;
      const requestConfig = {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${auth.value.access}`,
        },
        body: this.bodyRequest,
      };
      await useFetch<SegmentType>(
        `${BASE_URL}/api/v2/segment/${this.segmentCreation.guid}/`,
        requestConfig as UseFetchOptions,
      );
      // TODO: Remove file upload from FTP when upload ftp endpoint is provided
      if (!this.segmentCreation.ftp.showFiles) {
        await this.uploadFile();
      }
      this.loading = false;
    },
    async getExistingData(id: string) {
      const { data, error } = await getSegmentData(id);
      if (error.value?.statusCode === 401) {
        await refreshToken();
      } else if (data.value) {
        await this.setOnBoarderState(data.value);
      }
    },
    async setOnBoarderState(inboundData: SegmentType) {
      await this.getOptions();

      this.segmentCreation.guid = inboundData.guid;
      this.segmentCreation.name = inboundData.name;
      this.segmentCreation.description = inboundData.description;
      this.segmentCreation.selectedRegionId = inboundData.region;
      this.segmentCreation.selectedFileTypeId = 1;
      this.segmentCreation.selectedFileName = inboundData.file_name ?? " ";
      // Use utility function to map csvHeaders
      this.segmentCreation.csvHeaders = mapCsvHeaders(inboundData.preview?.[0] ?? []);
      // Use utility function to map firstTenRows
      this.segmentCreation.firstTenRows = mapFirstTenRows(
        inboundData.preview?.slice(1) ?? [],
        this.segmentCreation.csvHeaders,
      );
      this.step = 3;
    },
  },
});
